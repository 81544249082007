import React from "react";
import Advert from "../components/landingPage/advert/Advert";
import MainPage from "../components/landingPage/MainPage/MainPage";

const Home = () => {
  return (
    <div>
    <Advert />
    <MainPage />
		</div>
	);
};

export default Home;
